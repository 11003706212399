@import '../../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.name {
  display: flex;
  justify-content: space-between;
  /* margin-top: 15px; */
  margin-top: 30px;

  gap: 0 20px;
  @media (--viewportMedium) {
    /* margin-top: 32px; */
  }
  & > div {
    & > label {
      padding: 0 0 10px 0;
    }
    & > div{
      & > input{
        padding: 12px;
      }
    }
  }

  @media (max-width: 575px) {
    flex-direction: column;
    margin-top:20px;
    & > div:nth-child(2) {
      margin-top: 20px;
    }
  }
 
}

.firstNameRoot {
  /* width: calc(34% - 9px); */
  flex-basis: 50%;
  width: 50%;
  @media (max-width: 575px) {
    flex-basis: 100%;
    width: 100%;
  }
}

.lastNameRoot {
  /* width: calc(66% - 9px); */
  flex-basis: 50%;
  width: 50%;
  @media (max-width: 575px) {
    flex-basis: 100%;
    width: 100%;
  }
}

.fieldGrp {
  margin: 30px auto 0;
  @media(max-width:575px){
    margin: 20px auto 0;
  }
}

.otherTypeSignupLink {
  margin: 25px auto 0;
  text-align: center;
}

.password {
  composes: marketplaceModalPasswordMargins from global;
  /* margin-top: 15px; */
  margin-top: 30px;
  @media(max-width:575px){
    margin-top:20px;
  }
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;
  /* margin-top: 40px; */
  margin-top: 50px;
}

.sectionTitle {
  font-size: 30px;
  margin-top: -15px;
  margin-bottom: 25px;
  color: #000;
  @media (max-width: 575px) {
    font-size: 22px;
    margin-top: 0;
    margin-bottom:20px;
  }
}
.bottomMessage,
.partnerBottomMessage {
  /* color: red; */
  color: #000;

  margin-top: 5px;

  font-size: 14px;
  & a {
    /* color: red; */
    color: #000;

    font-weight: 800;
    text-decoration: none;
    border-bottom: 2px solid #000;
    &:hover{
      text-decoration:none;
    }
  }
}
.partnerBottomMessage {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.middleContainer {
  & > div {
    & > label {
      padding-top: 0;
      /* padding-bottom:5px; */
      padding-bottom: 10px;
    }
    & > div{
      & > input{
        @media(max-width:575px){
          padding: 12px 12px 12px 12px;
        }
      }
    }
  }
}
