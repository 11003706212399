@import '../../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.otherTypeLoginLink {
  margin: 25px auto 0;
  text-align: center;
  @media (max-width: 767px) {
    margin: 15px auto 0;
  }
  & a {
    font-weight: 800;
    text-decoration: none;
    border-bottom: 2px solid #000;
    &:hover{
      text-decoration:none;
    }
  }
}

.password {
  composes: marketplaceModalPasswordMargins from global;
  /* margin-top: 15px; */
  margin-top: 30px;
  @media(max-width:575px){
    margin-top:20px;
  }
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;
  margin-top: 40px;
}

/* Adjust the bottomWrapper's margin to match modals without the helper text */
.bottomWrapperText {
  composes: marketplaceModalBottomWrapperText from global;
}

.recoveryLink {
  composes: marketplaceModalHelperLink from global;
  font-size:13px;
}

.recoveryLinkInfo {
  composes: marketplaceModalHelperText from global;
}

.sectionTitle {
  font-size: 30px;
  margin-top: -15px;
  margin-bottom: 25px;
  color: #000;
  @media(max-width:575px){
    font-size: 22px;
    margin-top:0;
    margin-bottom:20px;
  }
}
.redLink {
  /* color: red; */
  color: #000;
}

.errMsg {
  font-size: 16px;
  letter-spacing: 0px;
  margin: 5px 0 10px 14px;
  color: red;
}
.LoginMiddleContainer {
  & > div {
    & > label {
      padding: 0 0 10px 0;
    }
    & > div{
      & > input{
        padding:12px;
      }
    }
  }
}
